<template>
  <div id="start-page" :class="'main-layout' + ' ' + state">
    <div id="login-wrapper">
      <div id="start-window" v-if="state == 'start'">
        <h1>Urban Nature Explorer</h1>
        <div id="description">
          A simulation game to address<br />
          sustainability challenges through<br />
          nature-based solutions.
        </div>
        <button @mousedown="showLogin">Start</button>
      </div>
      <div id="signup-window" v-if="state == 'signup'" v-bind:key="windowKey">
        <h1>Welcome on board!</h1>
        <form @submit.prevent>
          <label for="email" class="label">Email</label>
          <div class="form-error" v-if="errors.email">
            <div class="error-message">{{ errors.email }}</div>
            <div class="error-bubble">
              <img src="/images/ui/error-bubble.png" />
            </div>
          </div>
          <input
            type="email"
            name="email"
            v-model="email"
            :class="errorClass('email')"
          />
          <label for="password" class="label">Password</label>
          <div class="form-error" v-if="errors.password">
            <div class="error-message">{{ errors.password }}</div>
            <div class="error-bubble">
              <img src="/images/ui/error-bubble.png" />
            </div>
          </div>
          <input
            type="password"
            name="password"
            v-model="password"
            :class="errorClass('password')"
          />

          <div id="privacy">
            <div class="form-error" v-if="errors.privacy">
              <div class="error-message">{{ errors.privacy }}</div>
              <div class="error-bubble">
                <img src="/images/ui/error-bubble.png" />
              </div>
            </div>
            <div class="content">
              <input
                type="checkbox"
                name="privacy"
                v-model="privacy"
                :class="errorClass('privacy')"
              />
              <span
                >I accept the
                <a
                  class="link"
                  href="https://www.ceu.edu/privacy"
                  target="_blank"
                  >Privacy Policy</a
                >.</span
              >
            </div>
          </div>
          <button class="wide" @mousedown="signUpValidate">Sign up</button>
          <div class="login-signup">
            <p>
              Already a user?
              <a href="#" class="link" @mousedown.prevent="showLogin">Log in</a
              >.
            </p>
            <p>
              Forgot password?
              <a href="#" class="link" @mousedown.prevent="showReset"
                >Reset password</a
              >.
            </p>
            <p>
              <small
                >Please use Google Chrome or Mozilla Firefox on a desktop
                computer to ensure optimal experience.</small
              >
            </p>
          </div>
        </form>
      </div>
      <div id="login-window" v-if="state == 'login'" v-bind:key="loginKey">
        <h1>Welcome on board!</h1>
        <form @submit.prevent>
          <div class="info">{{ info }}</div>
          <label for="email" class="label">Email</label>
          <div class="form-error" v-if="errors.email">
            <div class="error-message">{{ errors.email }}</div>
            <div class="error-bubble">
              <img src="/images/ui/error-bubble.png" />
            </div>
          </div>
          <input
            type="email"
            name="email"
            v-model="email"
            :class="errorClass('email')"
          />
          <label for="password" class="label">Password</label>
          <div class="form-error" v-if="errors.password">
            <div class="error-message">{{ errors.password }}</div>
            <div class="error-bubble">
              <img src="/images/ui/error-bubble.png" />
            </div>
          </div>
          <input
            type="password"
            name="password"
            v-model="password"
            :class="errorClass('password')"
          />
          <button class="wide" @mousedown="loginValidate">Login</button>
          <div class="login-signup">
            <p>
              Not a user?
              <a href="#" class="link" @mousedown.prevent="showSignup"
                >Sign up</a
              >.
            </p>
            <p>
              Forgot password?
              <a href="#" class="link" @mousedown.prevent="showReset"
                >Reset password</a
              >.
            </p>
            <p>
              <small
                >Please use Google Chrome or Mozilla Firefox on a desktop
                computer to ensure optimal experience.</small
              >
            </p>
          </div>
        </form>
      </div>
      <div id="reset-window" v-if="state == 'reset'" v-bind:key="resetKey">
        <h1>Forgot password</h1>
        <p>
          <small
            >Please enter your email address below and we'll send you a link to
            reset your password.</small
          >
        </p>
        <form @submit.prevent>
          <label for="email" class="label">Email</label>
          <div class="form-error" v-if="errors.email">
            <div class="error-message">{{ errors.email }}</div>
            <div class="error-bubble">
              <img src="/images/ui/error-bubble.png" />
            </div>
          </div>
          <input
            type="email"
            name="email"
            v-model="email"
            :class="errorClass('email')"
          />

          <button class="wide" @mousedown="sendResetLink">
            Reset the password
          </button>
          <div class="login-signup">
            <span
              >Not a user?
              <a href="#" class="link" @mousedown.prevent="showLogin">Log in</a
              >.</span
            >
          </div>
        </form>
      </div>
    </div>

    <main-layout />
  </div>
</template>

<script>
import MainLayout from "../components/MainLayout";
import { logIn, signUp, currentUser, sendResetLink } from "../store";

export default {
  data: function () {
    return {
      state: "start",
      email: null,
      password: null,
      privacy: null,
      errors: {},
      windowKey: 0,
      loginKey: 0,
      resetKey: 0,
      info: "",
    };
  },
  components: {
    MainLayout,
  },
  created: function () {
    const user = currentUser();
    if (user) {
      console.log(user)
      if (user._profile.identities[0].providerType == "anon-user") {
        this.$store.dispatch("logOut").then(() => {
          this.state = this.$store.state.step
        });
      } else {
        this.redirectUser(user);
      }
    } else {
      this.state = this.$store.state.step || "start";
    }
  },
  watch: {
    errors: function () {
      console.log(this.errors);
    },
  },
  methods: {
    errorClass(field) {
      if (this.errors[field]) {
        return "error";
      } else return "";
    },
    showSignup: function () {
      this.$store.commit("setStep", "signup");
      this.state = "signup";
    },
    showReset: function () {
      this.state = "reset";
    },
    signUpValidate: function () {
      this.errors = {};
      if (!this.privacy) {
        this.errors.privacy = "You should accept the privacy policy.";
      }

      if (!this.email) {
        this.errors.email = "Email is required.";
      } else if (
        !this.email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        this.errors.email = "Email is not valid.";
      }

      if (!this.password) {
        this.errors.password = "Password is required.";
      }
      if (Object.values(this.errors).length == 0) {
        this.signUp(this.email, this.password);
      }
    },
    loginValidate() {
      this.errors = {};

      if (!this.email) {
        this.errors.email = "Email is required.";
      } else if (
        !this.email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        this.errors.email = "Email is not valid.";
      }

      if (!this.password) {
        this.errors.password = "Password is required.";
      }

      if (Object.values(this.errors).length == 0) {
        try {
          this.logIn(this.email, this.password);
        } catch (e) {
          console.log(e);
        }
      }
    },
    signUp(email, password) {
      this.errors = {};
      signUp(email, password)
        .then(() => {
          this.logIn();
          this.showLogin();
        })
        .catch((e) => {
          const error = e.toString().replace(/^.*\): ([^(]+)\(.*$/g, "$1");

          if (error.indexOf("password") > -1) {
            this.errors.password = error;
          } else {
            this.errors.email = error;
            console.log(error);
            console.log(this.errors);
          }
          this.windowKey = this.windowKey + 1;
        });
    },
    showLogin() {
      this.$store.commit("setStep", "login");
      this.state = "login";
    },
    logIn(email = null, password = null) {
      logIn({ email: this.email, password: this.password })
        .then(() => {
          this.redirectUser(currentUser());
        })
        .catch((e) => {
          const error = e.toString().replace(/^.*\): ([^(]+)\(.*$/g, "$1");
          if (error.indexOf("password") > -1) {
            this.errors.password = error;
          } else {
            this.errors.email = error;
          }
          this.loginKey = this.loginKey + 1;
        });
    },
    redirectUser(user) {
      const code = this.$route.params.roomId;

      if (code && code != "StartPage") {
        this.$store.dispatch('loadSolutionByCode', code).then(solution => {
          this.$store.commit("setCode", code);
          this.$store.commit("setSolution", solution);

          const redirect = () => {
            this.$store
              .dispatch("loadProjects", {
                _id: { $oid: solution.project },
              }).then((projects) => {
                this.$store.commit("setCurrentProject", projects[0]);
                this.$store.commit("setCurrentPage", "/ExploreSite");

                this.$router.push({
                  path: this.$store.state.currentPage,
                });
              });
          }

          redirect()

          // if (solution.room) {
          //   this.$game.joinOrCreateRoom(solution.room, {code: code}).then(() => {
          //     this.$store.commit("setRoomId", solution.room);
          //     redirect()
          //   })
          // }
          // else {
          //   this.$game.createRoom({code: code}).then((room) => {
          //     this.$store.commit("setRoomId", room.id);
          //     redirect();
          //   })
          // }
        }).catch((e) => {
          console.log(e)
          if (e.match(/access token expired/)) {
            this.$store.dispatch("logOut").then(() => {
              this.state = this.$store.state.step;
            });
          }

          this.$router.push({
            path: "/" + code,
          })
        })
      }
      else if (user.customData.admin) {
        const path = "/Admin";
        this.$store.commit("setCurrentPage", path);

        this.$router.push({
          path: path,
        });
      } else {
        this.$store
          .dispatch("loadProjects", {
            _id: { $oid: "64ca5c249b10cb76ca43246f" }, // Fictional Gyor as fallback
          })
          .then((projects) => {
            this.$store.commit("setCurrentProject", projects[0]);

            const path = this.$store.state.solution
              ? "/ExploreSite"
              : "/SelectScenario";
            this.$store.commit("setCurrentPage", path);

            this.$router.push({
              path: path,
            });
          });
      }
    },
    sendResetLink() {
      sendResetLink(this.email)
        .then(() => {
          this.$store.commit("setStep", "login");
          this.state = "login";
          this.info = "A password reset link was sent to your email address.";
          this.loginKey += 1;
        })
        .catch((e) => {
          const error = e.toString().replace(/^.*\): ([^(]+)\(.*$/g, "$1");
          this.errors.email = error;
          this.resetKey = this.resetKey + 1;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../styles/base.scss";
@import "../styles/game-components.scss";

.error-message {
  padding: 10px;
  background: #db0101;
  border-radius: 10px;
  color: white;
}

.error-bubble {
  margin-top: -2px;
  margin-left: 21px;
  height: 16px;
}

#login-wrapper {
  min-height: 300px;
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: white;
}

#login-wrapper .label {
  margin: 10px 10px;
  color: #2b4d37;
}

#start-window button {
  display: block;
  margin: 0 auto;
  padding: 10px 40px 14px 40px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

#login-wrapper input {
  border: 0;
  display: block;
  width: 100%;
  line-height: 58px;
  height: 58px;
  background: #fff;
  border: 1.5px solid;
  border-color: #2b4d37;
  box-sizing: border-box;
  border-radius: 10px;
}

#login-wrapper input.error {
  border-color: #db0101;
}
#login-wrapper input[type="checkbox"] {
  -webkit-appearance: none;
  padding: 3px 7px;
  border-radius: 3px;

  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  margin-right: 5px;
}

#login-wrapper input[type="checkbox"]:active,
input[type="checkbox"]:checked:active {
  background: #eb621c;
  border-color: #eb621c;
}

#login-wrapper input[type="checkbox"]:checked {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #fff;
  background: #eb621c;
}
#login-wrapper input[type="checkbox"]:checked:after {
  content: "\2714";
  font-size: 20px;
  position: absolute;
  top: 0px;
  left: 2px;
  color: #fff;
  line-height: 24px;
}

#signup-window #privacy {
  margin: 20px 0px;
}
#signup-window #privacy > div.content {
  display: flex;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  color: rgba(55, 55, 55, 0.88);
}

#start-window {
  width: 388px;
  //	height: 500px;
  margin: 34px auto 0 auto;
}

#start-window #description {
  width: 380px;
  text-align: center;
  margin: 20px auto 40px auto;
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
}

#start-window #logo {
  position: initial;
  margin-bottom: 20px;
}

#signup-window,
#login-window,
#reset-window {
  width: 388px;
  height: 500px;
  margin: 0 auto;
  padding: 0 40px;
  font-size: 18px;
  line-height: 24px;
}

#login-window button,
#reset-window button {
  margin: 20px 0;
}
.login-signup {
  margin-top: 20px;
}
.info {
  text-align: center;
}

@media screen and (max-width: 700px) {
  #login-wrapper {
    height: auto;
  }
  #start-window {
    height: auto;
    padding-bottom: 50px;
  }
}
</style>
